import React, { useEffect, useState } from "react";
import dlclogo from "../assets/LOGO_WHITE.png";
import dlclogoBlack from "../assets/LOGO_NERO.png";

import styles from "./Home.module.scss";

import calendly from "../assets/CalendlyIcon.png";
import revolut from "../assets/Revolut.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCcPaypal,
  faFacebook,
  faInstagram,
  faLinkedin,
  faPaypal,
  faTiktok,
  faWhatsapp,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import axiosHelper from "../helpers/axiosHelper";
import { ContactData } from "../global";
import { useStoreState } from "pullstate";
import BusinessCardStore, {
  setContactCopyableTexts,
  setContactCryptoWallets,
  setContactFiles,
  setContactLinks,
  setCurrentContact,
} from "../store/BusinessCardStore";
import { getCurrentContact } from "../store/Selector";
import {
  faCopy,
  faEnvelope,
  faFile,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBriefcase,
  faCloudArrowDown,
  faFileAlt,
  faFont,
  faGlobe,
  faLink,
  faPhone,
  faTv,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import PopUpExample from "../components/PopUpExample/PopUpExample";

import phoneblack from "../assets/phoneblack.png";
import phonewhite from "../assets/phonewhite.png";

import mailblack from "../assets/mailblack.png";
import mailwhite from "../assets/mailwhite.png";

import pcblack from "../assets/pcblack.png";
import pcwhite from "../assets/pcwhite.png";

import exchangeBlack from "../assets/exchangeblack.png";
import exchangeWhite from "../assets/exchangewhite.png";
import PopUpExchange from "../components/PopUpExchange/PopUpExchange";

const Home: React.FC = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const contact = useStoreState(BusinessCardStore, getCurrentContact);
  const [popup, setPopup] = useState(false);
  const [popupExchange, setPopupExchange] = useState(false);
  const [uuid, setUuid] = useState("");
  // const [contact, setContact] = useState<Contact>();

  const [language, setLanguage] = useState("it");

  const [lngOpen, setLngOpen] = useState(false);

  const [base64Img, setBase64Img] = useState("");
  const [website, setWebsite] = useState("");

  const redirect = useNavigate();

  // useEffect(() => {
  //   console.log(params.name);
  //   if (contact.uuid !== params.name) {
  //     // i18n.changeLanguage("en");
  //     getContact();
  //   }
  // }, []);

  useEffect(() => {
    if (params.name) {
      if (uuid !== params.name) setUuid(params.name);
    }
  }, [params.name]);

  useEffect(() => {
    if (uuid) {
      getContact();
      getContactFiles();
      getContactLinks();
      getContactCopyableTexts();
      getContactCryptoWallets();
    }
  }, [uuid]);

  const getContactFiles = async () => {
    await axiosHelper
      .getContactFiles(uuid)
      .then((response) => {
        setContactFiles(response.files);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getContactLinks = async () => {
    await axiosHelper
      .getContactLinks(uuid)
      .then((response) => {
        setContactLinks(response.links);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getContactCopyableTexts = async () => {
    await axiosHelper
      .getContactCopyableTexts(uuid)
      .then((response) => {
        setContactCopyableTexts(response.copyable_texts);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getContactCryptoWallets = async () => {
    await axiosHelper
      .getContactCryptoWallets(uuid)
      .then((response) => {
        setContactCryptoWallets(response.wallets);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).catch((err) => {
      console.error("Errore nella copia: ", err);
    });
  }

  const goToPlatform = () => {
    if (contact.ismanaged === 1) {
      window.open("https://platform.businesscard.dlctech.it", "_blank");
    } else {
      window.open(
        `https://platform.businesscard.dlctech.it/register/${uuid}`,
        "_blank"
      );
    }
  };

  function convertImageToBase64(imgUrl?: string, callback?: any) {
    if (imgUrl) {
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx && ctx.drawImage(image, 0, 0);
        const dataUrl = canvas.toDataURL();
        callback && callback(dataUrl);
      };
      image.src = imgUrl;
    } else {
      // console.log("no image");
    }
  }

  useEffect(() => {
    // console.log("useeffect 2");

    convertImageToBase64(contact.imageurl, (res: any) => {
      setBase64Img(res.substring(22, res.length - 1));
    });
  }, [contact]);

  const openModal = () => {
    // if (os === "iOS") {
    //   setPopup(!popup);
    // } else {
    downloadContact();
    // }
  };

  const openModalExchange = () => {
    setPopupExchange(!popupExchange);
  };

  const downloadContact = async () => {
    if (contact) {
      await axiosHelper.addCountactCounter(uuid);
      let vcard = `BEGIN:VCARD\nVERSION:3.0\nFN;CHARSET=UTF-8:`;

      if (contact.name && contact.surname) {
        vcard =
          vcard +
          `BEGIN:VCARD\nVERSION:3.0\nFN;CHARSET=UTF-8:` +
          contact.name +
          ` ` +
          contact.surname +
          `\nN;CHARSET=UTF-8:` +
          contact.surname +
          `;` +
          contact.name +
          `;;;`;
      }
      if (contact.email) {
        vcard =
          vcard + `\nEMAIL;CHARSET=UTF-8;type=email,INTERNET:` + contact.email;
      }
      if (contact.workmail) {
        vcard =
          vcard +
          `\nEMAIL;CHARSET=UTF-8;type=work email,INTERNET:` +
          contact.workmail;
      }
      if (contact.imageurl) {
        // const imagetype = detectImageType(base64Img);
        // if (imagetype === "JPEG") {
        // console.log("jpeg");
        vcard = vcard + `\nPHOTO;ENCODING=b;TYPE=JPEG:` + base64Img;
        // } else if (imagetype === "PNG") {
        //   console.log("png");
        //   vcard = vcard + `\nPHOTO;ENCODING=b;TYPE=PNG:` + base64Img;
        // }
      }
      if (contact.website) {
        vcard = vcard + `\nURL;TYPE=website;CHARSET=UTF-8:` + contact.website;
      }
      if (contact.phone) {
        vcard =
          vcard +
          `\nTEL;TYPE=phone number,VOICE:` +
          contact.prefix +
          contact.phone;
        // TEL;TYPE=WORK,VOICE:+39876543210
      }
      if (contact.workphone) {
        vcard =
          vcard +
          `\nTEL;TYPE=work phone number,VOICE:` +
          contact.workprefix +
          contact.workphone;
        // TEL;TYPE=WORK,VOICE:+39876543210
      }
      if (contact.role) {
        vcard = vcard + `\nROLE;CHARSET=UTF-8:` + contact.role;
      }
      if (contact.company) {
        vcard = vcard + `\nORG;CHARSET=UTF-8:` + contact.company;
      }
      if (contact.facebook) {
        vcard = vcard + "\nX-SOCIALPROFILE;TYPE=facebook:" + contact.facebook;
      }
      if (contact.instagram) {
        vcard = vcard + `\nX-SOCIALPROFILE;TYPE=Instagram:` + contact.instagram;
      }
      if (contact.linkedin) {
        vcard = vcard + `\nX-SOCIALPROFILE;TYPE=LinkedIn:` + contact.linkedin;
      }

      // +"\nNICKNAME:Cecche"+

      // "\nPHOTO;TYPE=JPEG;VALUE=URI:" +
      // contact.img +

      // "\nX-SOCIALPROFILE;TYPE=facebook:" +
      // contact.facebook +
      // `\nX-SOCIALPROFILE;TYPE=Instagram:` + contact.instagram + ;
      vcard = vcard + `\nNOTE;CHARSET=UTF-8:Created by DLC Tech`;
      vcard = vcard + `\nEND:VCARD`;
      // console.log(vcard);
      let blob = new Blob([vcard], { type: "text/vcard" });

      let url = URL.createObjectURL(blob);
      const newLink = document.createElement("a");
      newLink.download = contact.name + ".vcf";
      newLink.textContent = contact.name;
      newLink.href = url;
      newLink.click();
    }
  };

  const getContact = async () => {
    if (uuid) {
      if (uuid !== contact.uuid) {
        await axiosHelper
          .getContact(uuid)
          .then((response: ContactData) => {
            // console.log(response);
            // console.log(navigator.userAgent);
            setCurrentContact(response);
          })
          .catch((error) => console.log(error));
        if (params.Analytics !== "false") {
          const addCounter = await axiosHelper
            .addCounter(uuid)
            .then(() => console.log("ho mandato analisi"));
        }
      }
    }
  };

  // useEffect(() => {
  //   console.log(params);
  // }, []);

  useEffect(() => {
    if (contact.name !== "") {
      // console.log(contact.web_interface_name);
      if (contact.web_interface_name !== "contact") {
        redirect(`/${contact.web_interface_name}/${contact.uuid}`);
      }
    }
    if (contact.website !== null && contact.website !== "") {
      let url = new URL(contact.website);
      setWebsite(url.hostname);
    }
  }, [contact]);

  const os = (() => {
    if (/windows/i.test(navigator.userAgent)) {
      return "Windows";
    } else if (/iphone/i.test(navigator.userAgent)) {
      return "iOS";
    } else if (/ipad/i.test(navigator.userAgent)) {
      return "iOS";
    } else if (/macintosh/i.test(navigator.userAgent)) {
      return "Mac OS";
    }
    // more user agents to detect...
  })();

  return (
    <>
      {contact.name === null && (
        <>
          <div className="d-flex flex-row col-12 align-items-center justify-content-center bg-white">
            <div
              className="d-flex flex-column align-items-center justify-content-start col-12 col-xl-4 mt-10"
              style={{ height: "100vh", backgroundColor: "white" }}
            >
              <img src={dlclogoBlack} style={{ maxWidth: "80vw" }} />
              <div
                className="d-flex flex-row col-12   light-title fw-bold  color-black align-items-center justify-content-center text-center"
                style={{ marginTop: "20vh" }}
              >
                Welcome !
              </div>
              <div
                className="d-flex flex-row col-12   light-title  color-black align-items-center justify-content-center text-center p-5"
                style={{ lineHeight: "1.3" }}
              >
                Let’s set together your <br />
                DLC Business Card
              </div>
              <div className="d-flex flex-row col-12  mt-7 sub-title  color-black align-items-center justify-content-center text-center ">
                <div
                  className={`${styles.platformBtn} d-flex flex-row col-7 text-xxl  align-items-center justify-content-center cursor-pointer`}
                  onClick={() =>
                    window.open(
                      `https://platform.businesscard.dlctech.it/register/${params.name}`
                    )
                  }
                >
                  Join platform
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {contact.name !== null && (
        <>
          <div
            className=" d-flex  flex-column align-items-center justify-content-start col-12"
            style={{
              minHeight: "100vh",
              backgroundColor: `${
                contact.mode === "NIGHT" ? "black" : "white"
              }`,
            }}
          >
            <div className="d-flex flex-column align-items-center justify-content-center col-md-4 col-xl-3 col-12 ">
              {/* LOGO */}
              <div
                className={` mt-5 d-flex flex-row align-items-center justify-content-center col-12 ${styles.logoContainer}`}
              >
                {contact.logourl && (
                  <img alt="logo" src={contact.logourl} className="mb-4 " />
                )}
              </div>
              {/* IMAGE */}
              <div
                className={`d-flex mt-4 flex-row align-items-center justify-content-center col-12 ${styles.imageContainer}`}
              >
                {contact.imageurl && (
                  <img
                    id="img"
                    alt="img"
                    src={
                      contact?.imageurl !== null ? contact.imageurl : mailwhite
                    }
                    style={{
                      border: `${
                        contact.mode === "LIGHT"
                          ? "4px solid black"
                          : "7px solid white"
                      }`,
                    }}
                  />
                )}
              </div>
              <div className="d-flex flex-column align-items-center justify-content-start mt-5 col-12  ">
                <span
                  className={`sub-title-business ${
                    contact.mode === "NIGHT" ? "color-white" : "color-black"
                  }`}
                >
                  {contact?.name + ` ` + contact?.surname}
                </span>
                <span
                  className={`text-business mt-4 text-center ${
                    contact.mode === "NIGHT" ? "color-white" : "color-black"
                  }`}
                >
                  {/* CEO & Co-Founder at DLC Tech */}
                  {contact?.role}
                  {/* <br /> <br />
               {contact?.company} */}
                </span>
                <div className="d-flex flex-row align-items-center justify-content-around col-12 px-3 ">
                  <div
                    className={`mt-5  d-flex flex-row align-items-center justify-content-center button-text col-6 ${
                      contact.mode === "NIGHT"
                        ? `${styles.addButtonGreen}`
                        : `${styles.addButtonBlue}`
                    }`}
                    // onClick={downloadContact}
                    onClick={openModal}
                  >
                    <FontAwesomeIcon
                      icon={faCloudArrowDown as IconProp}
                      style={{ fontSize: "20px" }}
                      className="me-2"
                    />
                    {t(`HOME_ADD_CONTACT`)}
                  </div>
                  <PopUpExample
                    popup={popup}
                    close={() => setPopup(!popup)}
                    success={() => downloadContact()}
                    mode={contact.mode}
                  />
                  <div
                    className={`mt-5  d-flex flex-row align-items-center cursor-pointer justify-content-center button-text col-5  ${
                      contact.mode === "NIGHT"
                        ? `${styles.exchangeButtonBlack}`
                        : `${styles.exchangeButtonWhite}`
                    }`}
                    // onClick={downloadContact}
                    onClick={() => {
                      console.log("click");
                      openModalExchange();
                    }}
                  >
                    <div className={`${styles.btniconContainer} me-2`}>
                      <img
                        src={
                          contact.mode === "NIGHT"
                            ? exchangeWhite
                            : exchangeBlack
                        }
                      />
                    </div>
                    {t(`HOME_EXCHANGE`)}
                  </div>
                </div>
              </div>
              <PopUpExchange
                popup={popupExchange}
                close={() => setPopupExchange(!popupExchange)}
                success={() => console.log("success")}
                mode={"LIGHT"}
                name={contact.name}
                uuid={contact.uuid}
              />
              <div className="d-flex flex-column align-items-center justify-content-center col-12 py-4">
                {/* {(contact?.facebook ||
                  contact?.linkedin ||
                  contact?.instagram ||
                  contact.twitter ||
                  contact.youtube ||
                  contact.tiktok ||
                  contact?.whatsapp !== 0) && (
                  <span
                    className={`sub-title fw-bold  ${
                      contact.mode === "NIGHT" ? "color-white" : "color-black"
                    }`}
                  >
                    Social:
                  </span>
                )} */}
                <div className=" d-flex  flex-row align-items-center justify-content-around col-11 flex-wrap  mt-5 pb-5">
                  {contact.linkedin && (
                    <span
                      className={`${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      }`}
                    >
                      <FontAwesomeIcon
                        onClick={() => {
                          contact?.linkedin &&
                            window.location.replace(contact?.linkedin);
                        }}
                        icon={faLinkedin as IconProp}
                        fontSize={"5rem"}
                      />
                    </span>
                  )}
                  {contact.instagram && (
                    <span
                      className={`bg-white d-flex flex-row align-items-center justify-content-center ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      }`}
                      style={{
                        borderRadius: "50%",
                        height: "5rem",
                        width: "5rem",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faInstagram as IconProp}
                        onClick={() => {
                          contact?.instagram &&
                            window.location.replace(contact?.instagram);
                        }}
                        fontSize={"4rem"}
                        className="color-black"
                      />
                    </span>
                  )}
                  {contact.facebook &&
                    (console.log(contact.facebook),
                    (
                      <span>
                        <FontAwesomeIcon
                          icon={faFacebook as IconProp}
                          onClick={() => {
                            contact?.facebook &&
                              window.location.replace(contact?.facebook);
                          }}
                          fontSize={"5rem"}
                          style={
                            contact.mode === "LIGHT"
                              ? { color: "black" }
                              : { color: "white" }
                          }
                        />
                      </span>
                    ))}
                  {contact.tiktok && (
                    <span
                      className={`${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      }`}
                    >
                      <FontAwesomeIcon
                        onClick={() => {
                          contact?.tiktok &&
                            window.location.replace(contact?.tiktok);
                        }}
                        icon={faTiktok as IconProp}
                        fontSize={"5rem"}
                      />
                    </span>
                  )}
                  {contact.youtube && (
                    <span
                      className={`${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      }`}
                    >
                      <FontAwesomeIcon
                        onClick={() => {
                          contact?.youtube &&
                            window.location.replace(contact?.youtube);
                        }}
                        icon={faYoutube as IconProp}
                        fontSize={"5rem"}
                      />
                    </span>
                  )}
                  {contact.twitter && (
                    <span
                      className={`${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      }`}
                    >
                      <FontAwesomeIcon
                        onClick={() => {
                          contact?.twitter &&
                            window.location.replace(contact?.twitter);
                        }}
                        icon={faXTwitter as IconProp}
                        fontSize={"5rem"}
                      />
                    </span>
                  )}
                  {contact.whatsapp !== 0 && (
                    <span
                      className={`bg-white d-flex flex-row align-items-center justify-content-center ${
                        contact.mode === "LIGHT" ? "color-black" : "color-white"
                      }`}
                      style={{
                        borderRadius: "50%",
                        height: "5rem",
                        width: "5rem",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faWhatsapp as IconProp}
                        onClick={() => {
                          contact?.whatsapp !== 0 &&
                            window.open(
                              `https://wa.me/${contact.prefix}${contact.phone}`,
                              "_blank"
                            );
                        }}
                        fontSize={"4rem"}
                        className="color-black"
                      />
                    </span>
                  )}
                </div>
              </div>
              <div className="d-flex  flex-column align-items-start justify-content-start  px-4 col-12 pb-4 ">
                {contact.phone && (
                  <a
                    href={`tel:${contact.prefix}${contact.phone}`}
                    style={{ textDecoration: "none" }}
                    className=" col-12 "
                  >
                    <span
                      className={`text-business  d-flex flex-row align-items-center justify-content-start col-12   ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      } ${styles.websitestring}`}
                    >
                      <div className={`${styles.iconContainer}   `}>
                        <img
                          src={
                            contact.mode === "NIGHT" ? phonewhite : phoneblack
                          }
                        />
                      </div>
                      <span className="col-12  d-flex flex-row align-items-center justify-content-center overflow-hidden">
                        {contact?.phone}
                      </span>
                    </span>
                  </a>
                )}
                {contact.workphone && (
                  <a
                    href={`tel:${contact.workprefix}${contact.workphone}`}
                    style={{ textDecoration: "none" }}
                    className=" col-12 "
                  >
                    <span
                      className={`text-business mt-3 d-flex flex-row align-items-center justify-content-start col-12   ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      } ${styles.websitestring}`}
                    >
                      <div className={`${styles.iconContainer}   `}>
                        {/* <img
                          src={
                            contact.mode === "NIGHT" ? phonewhite : phoneblack
                          }
                        /> */}
                        <FontAwesomeIcon
                          icon={faBriefcase as IconProp}
                          fontSize={"30px"}
                          className={`${
                            contact.mode === "NIGHT"
                              ? "color-white"
                              : "color-black"
                          }`}
                        />
                      </div>
                      <span className="col-12  d-flex flex-row align-items-center justify-content-center overflow-hidden">
                        {contact?.workphone}
                      </span>
                    </span>
                  </a>
                )}
                {contact.email && (
                  <a
                    href={`mailto:${contact.email}`}
                    style={{ textDecoration: "none" }}
                    className="col-12"
                  >
                    <span
                      className={`text-business mt-3  d-flex flex-row align-items-center justify-content-start col-12  ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      } ${styles.websitestring}`}
                    >
                      <div className={`${styles.iconContainer}  `}>
                        <img
                          src={contact.mode === "NIGHT" ? mailwhite : mailblack}
                        />
                      </div>
                      <span className="  d-flex flex-row align-items-center justify-content-center overflow-hidden text-center col-12">
                        Send Email
                      </span>
                    </span>
                  </a>
                )}
                {contact.website && (
                  <a
                    href={`${contact.website}`}
                    style={{ textDecoration: "none" }}
                    className="col-12"
                  >
                    <span
                      className={`text-business mt-3 d-flex flex-row align-items-center justify-content-start col-12 ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      } ${styles.websitestring}`}
                    >
                      {/* <img src={websiteIcon} style={{color:'#ffffff', maxHeight:'30px'}}/> */}
                      <div className={`${styles.iconContainer}   `}>
                        <img
                          src={contact.mode === "NIGHT" ? pcwhite : pcblack}
                        />
                      </div>
                      <span className="col-12 d-flex flex-row align-items-center justify-content-center overflow-hidden ">
                        {/* {contact?.website.substring(8, contact.website.length)} */}
                        {website}
                      </span>
                    </span>
                  </a>
                )}

                {contact.links &&
                  contact.links.length > 0 &&
                  contact.links.map((link) => (
                    <>
                      <div
                        className={`d-flex flex-row align-items-center justify-content-start col-12 mt-3 ${
                          contact.mode === "NIGHT"
                            ? "color-white"
                            : "color-black"
                        } ${styles.websitestring}`}
                        key={link.pos}
                      >
                        <div className={`${styles.iconContainer}   `}>
                          <FontAwesomeIcon
                            icon={faLink as IconProp}
                            style={{ fontSize: "28px" }}
                          />
                        </div>
                        <a
                          href={`${link.link}`}
                          style={{ textDecoration: "underline" }}
                          className="col-12"
                        >
                          <span
                            className={`text-business mt-3 d-flex flex-row align-items-center justify-content-center col-12  ${
                              contact.mode === "NIGHT"
                                ? "color-white"
                                : "color-black"
                            } ${styles.websitestring}`}
                          >
                            {/* <img src={websiteIcon} style={{color:'#ffffff', maxHeight:'30px'}}/> */}
                            {link.label}
                          </span>
                        </a>
                      </div>
                    </>
                  ))}
              </div>
              {(contact.paypal_link ||
                contact.calendly_link ||
                contact.revolut_link) && (
                <div
                  className={`col-12  d-flex flex-row align-items-center justify-content-around   
                     `}
                >
                  {contact.paypal_link && (
                    <span
                      className={`d-flex flex-row align-items-center justify-content-center  text-s  col-3 ${
                        styles.usefulLinkIconContainer
                      } ${contact.mode === "DARK" ? "bg-black" : "bg-white"}`}
                      onClick={() => {
                        window.open(contact.paypal_link, "_blank");
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCcPaypal as IconProp}
                        style={{ fontSize: "35px" }}
                      />
                    </span>
                  )}
                  {contact.calendly_link && (
                    <span
                      className={`d-flex flex-row align-items-center justify-content-center  text-s  col-3  ${
                        styles.usefulLinkIconContainer
                      } ${contact.mode === "DARK" ? "bg-black" : "bg-white"}`}
                      onClick={() => {
                        window.open(contact.calendly_link, "_blank");
                      }}
                    >
                      <img src={calendly} />
                    </span>
                  )}
                  {contact.revolut_link && (
                    <span
                      className={`d-flex flex-row align-items-center justify-content-center  text-s  col-3  ${
                        styles.usefulLinkIconContainer
                      } ${contact.mode === "DARK" ? "bg-black" : "bg-white"}`}
                      onClick={() => {
                        window.open(contact.revolut_link, "_blank");
                      }}
                    >
                      <img src={revolut} />
                    </span>
                  )}
                </div>
              )}

              {contact.copyable_texts && contact.copyable_texts.length > 0 && (
                <>
                  <div className="d-flex flex-column align-items-center justify-content-center col-12 py-4 px-3 ">
                    <span
                      className={`d-flex flex-row col-12 align-items-center justify-content-center sub-title ${
                        contact.mode === "LIGHT" ? "color-black" : "color-white"
                      }`}
                    >
                      {/* Copyable Text: */}
                    </span>
                    <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-4">
                      {contact.copyable_texts.map((text) => (
                        <>
                          <div
                            className={`col-12  d-flex flex-row align-items-center justify-content-around   ${
                              contact.mode === "LIGHT"
                                ? styles.copyableTextContainerWhite
                                : styles.copyableTextContainerBlack
                            } ${styles.copyableTextContainer}`}
                            key={text.pos}
                          >
                            <span className="d-flex flex-row align-items-center justify-content-center  text-s  col-1 ">
                              {/* <FontAwesomeIcon
                                icon={faFont as IconProp}
                                style={{ fontSize: "25px" }}
                              /> */}
                            </span>
                            <span className="d-flex flex-row align-items-center justify-content-center  text-x  col-9 ">
                              {text.text}
                            </span>
                            <span
                              className={`d-flex flex-row align-items-center justify-content-center  text-s  col-1 cursor-pointer ${
                                styles.copyBtn
                              } ${
                                contact.mode === "LIGHT"
                                  ? styles.btnBlack
                                  : styles.btnWhite
                              }`}
                              onClick={() => copyToClipboard(text.text)}
                            >
                              <FontAwesomeIcon
                                icon={faCopy as IconProp}
                                style={{ fontSize: "17px" }}
                              />
                            </span>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </>
              )}
              {contact.crypto_wallets && contact.crypto_wallets.length > 0 && (
                <>
                  <div className="d-flex flex-column align-items-center justify-content-center col-12 py-4 px-3 ">
                    <span
                      className={`d-flex flex-row col-12 align-items-center justify-content-center sub-title ${
                        contact.mode === "LIGHT" ? "color-black" : "color-white"
                      }`}
                    >
                      Crypto Wallets:
                    </span>
                    <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-4">
                      {contact.crypto_wallets.map((wallet, index) => (
                        <>
                          <div
                            className={`col-12  d-flex flex-row align-items-center justify-content-around   ${
                              contact.mode === "LIGHT"
                                ? styles.cryptoWalletContainerWhite
                                : styles.cryptoWalletContainerBlack
                            } `}
                            key={index}
                          >
                            <span
                              className={`d-flex flex-row align-items-center justify-content-center  text-s  col-1 ${styles.cryptoLogoContainer}`}
                            >
                              <img src={wallet.logourl} />
                            </span>
                            <span className="d-flex flex-column align-items-start justify-content-start  h-100  text-s  col-9 ">
                              <span className="text  d-flex flex-row col-12 align-items-center justify-content-center h-50 ">
                                {wallet.name}
                              </span>
                              <span className="text-xxl  d-flex flex-row col-12 align-items-center justify-content-center h-50">
                                {wallet.public_key}
                              </span>
                            </span>
                            <span
                              className={`d-flex flex-row align-items-center justify-content-center  text-s  col-1 cursor-pointer ${
                                styles.copyBtn
                              } ${
                                contact.mode === "LIGHT"
                                  ? styles.btnBlack
                                  : styles.btnWhite
                              }`}
                              onClick={() => copyToClipboard(wallet.public_key)}
                            >
                              <FontAwesomeIcon
                                icon={faCopy as IconProp}
                                style={{ fontSize: "17px" }}
                              />
                            </span>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </>
              )}
              {contact.files && contact.files.length > 0 && (
                <>
                  <div className="d-flex flex-column align-items-center justify-content-center col-12 py-4 ">
                    <span
                      className={`d-flex flex-row col-12 align-items-center justify-content-center sub-title ${
                        contact.mode === "LIGHT" ? "color-black" : "color-white"
                      }`}
                    >
                      Files:
                    </span>
                    <div className="d-flex flex-row col-12 align-items-center justify-content-around mt-4">
                      {contact.files.map((file) => (
                        <>
                          <div
                            className={`col-3  d-flex flex-column align-items-center justify-content-center  ${
                              contact.mode === "LIGHT"
                                ? "color-black"
                                : "color-white"
                            } ${styles.fileContainer}`}
                            key={file.pos}
                          >
                            <a
                              className={` ${styles.fileLink} ${
                                contact.mode === "LIGHT"
                                  ? "color-black"
                                  : "color-white"
                              }`}
                              href={file.path}
                              download={file.label}
                            >
                              <FontAwesomeIcon
                                icon={faFileAlt as IconProp}
                                style={{ fontSize: "60px" }}
                              />
                              <span className="d-flex flex-row align-items-center justify-content-center col-12 text-s mt-3 text-center">
                                {file.label}
                              </span>
                            </a>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </>
              )}
              {/* EX SOCIAL */}
              <div
                className={`  ${
                  contact.mode === "NIGHT" ? "bg-black" : "bg-white"
                } ${styles.powered}`}
                onClick={() => (window.location.href = "https://dlctech.it")}
              >
                <span
                  className={`${
                    contact.mode === "NIGHT" ? "color-white" : "color-black"
                  } `}
                >
                  powered by
                </span>
                <img
                  className="ms-2"
                  src={contact.mode === "NIGHT" ? dlclogo : dlclogoBlack}
                />
              </div>
              <div className="d-flex flex-row col-12  align-items-center justify-content-center mb-5">
                <span
                  className={`${styles.platformBtn} d-flex flex-row col-6 text-s align-items-center justify-content-center cursor-pointer fw-bold color-black`}
                  onClick={() => goToPlatform()}
                >
                  DLC Business Card Platform
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
