import { Store } from "pullstate";
import {
  ContactData,
  ContactFile,
  ContactLink,
  CopyableText,
  CryptoWallet,
} from "../global";

export interface IBusinessCardStore {
  currentContact: ContactData;
}

const BusinessCardStore = new Store<IBusinessCardStore>({
  currentContact: {
    name: "",
    surname: "",
    email: "",
    phone: "",
    workmail: "",
    role: "",
    linkedin: "",
    facebook: "",
    instagram: "",
    whatsapp: 0,
    company: "",
    website: "",
    uuid: "",
    mode: "",
    imageurl: "",
    logourl: "",
    custom_link: "",
    link1: "",
    label_link1: "",
    link2: "",
    label_link2: "",
    twitter: "",
    youtube: "",
    tiktok: "",
    prefix: "",
    file1url: "",
    file2url: "",
    file3url: "",
    web_interface_name: "",
    file1label: "",
    file2label: "",
    file3label: "",
    workprefix: "",
    workphone: "",
    ismanaged: 0,
    files: [],
    links: [],
    copyable_texts: [],
    crypto_wallets: [],
    paypal_link: "",
    revolut_link: "",
    calendly_link: "",
  },
});

export default BusinessCardStore;

export const setCurrentContact = (contact: ContactData) => {
  BusinessCardStore.update((s) => {
    s.currentContact = contact;
  });
};

export const setContactFiles = (files: ContactFile[]) => {
  BusinessCardStore.update((s) => {
    s.currentContact.files = files;
  });
};

export const setContactLinks = (links: ContactLink[]) => {
  BusinessCardStore.update((s) => {
    s.currentContact.links = links;
  });
};

export const setContactCopyableTexts = (copyable_texts: CopyableText[]) => {
  BusinessCardStore.update((s) => {
    s.currentContact.copyable_texts = copyable_texts;
  });
};

export const setContactCryptoWallets = (crypto_wallets: CryptoWallet[]) => {
  BusinessCardStore.update((s) => {
    s.currentContact.crypto_wallets = crypto_wallets;
  });
};
